import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonPage, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Menu from './components/Menu';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useEffect, useState } from 'react';
//import useAuth from './data/useAuth';
//import Loading from './pages/Loading';
//import Landing from './pages/Landing';
//import { getUserInfo } from './data/api';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Landing from './pages/Landing';




setupIonicReact();


export const ColorContext = React.createContext<any>(null);
const queryClient = new QueryClient();

const App: React.FC = () => {
    const [color, setColor] = useState("#3880ff");

    return (
        <QueryClientProvider client={queryClient}>
            <ColorContext.Provider value={[color, setColor]}>
                <MyOremSite />
                <ReactQueryDevtools />
            </ColorContext.Provider>
        </QueryClientProvider>
    );
}
    
const MyOremSite: React.FC = () => {

    const [darkMode, setDarkMode] = useState(false);

    return (
        <IonApp className={`${darkMode ? "dark-theme" : ""}`}>

            <IonReactRouter >
                <IonSplitPane contentId="ubmain">

                    <Menu darkMode={darkMode} setDarkMode={setDarkMode} />

                    <Route path="/" exact>
                        <Redirect to="/Landing" />
                    </Route>

                    <IonRouterOutlet id="ubmain">
                        
                        <Route
                            path="/Landing"
                            exact
                            render={props => {
                                return (<Landing />);
                            }}
                        />


                      

                    </IonRouterOutlet>
                
                </IonSplitPane>
            </IonReactRouter>
        </IonApp>
    );
}

export default App;
