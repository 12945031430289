

export function formatToUSDate(dateString) {
    const dateParts = dateString.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
}


export function formatDateYYYMMDD (dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
}


export function validateCreditCardNumber(cardNumber) {
    // Remove any non-digit characters
    cardNumber = cardNumber.replace(/\D/g, '');

    // Check if the card number is empty or contains non-digit characters
    if (cardNumber.length === 0 || !/^\d+$/.test(cardNumber)) {
        return false;
    }

    // Check if the card number has a valid length
    if (cardNumber.length < 13 || cardNumber.length > 19) {
        return false;
    }

    // Apply the Luhn algorithm
    var sum = 0;
    var doubleDigit = false;

    for (var i = cardNumber.length - 1; i >= 0; i--) {
        var digit = parseInt(cardNumber.charAt(i), 10);

        if (doubleDigit) {
            digit *= 2;

            if (digit > 9) {
                digit -= 9;
            }
        }

        sum += digit;
        doubleDigit = !doubleDigit;
    }

    return sum % 10 === 0;
}

export function validateCreditCardExpiration(expirationDate) {
        const cleanedExpirationDate = expirationDate.replace(/\D/g, '');

        if (cleanedExpirationDate.length !== 6 && cleanedExpirationDate.length !== 7) {
            return false;
        }

        let month;
        let year;
        if (cleanedExpirationDate.length === 6) {
            month = cleanedExpirationDate.slice(0, 2);
            year = cleanedExpirationDate.slice(2);
        } else {
            month = cleanedExpirationDate.slice(0, 2);
            year = cleanedExpirationDate.slice(3);
        }

        month = parseInt(month, 10);
        year = parseInt(year, 10);

        if (month < 1 || month > 12) {
            return false;
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear() % 100;
        const currentMonth = currentDate.getMonth() + 1;

    if (year < currentYear || (year === currentYear && month < currentMonth)) {
        return false;
    }

        return true;
}

export function validateCVV(cvv) {
    // Remove any non-digit characters
    cvv = cvv.replace(/\D/g, '');

    // Check if the CVV has a valid length
    if (cvv.length !== 3 && cvv.length !== 4) {
        return false;
    }

    // Check if the CVV contains only digits
    if (!/^\d+$/.test(cvv)) {
        return false;
    }

    return true;
}

export function validateDonateAmount(value) {
    // Check if the value is an integer
    if (!Number.isInteger(value)) {
        return false;
    }
    // Check if the value is an integer
    if (Math.floor(value) !== value) {
        return false;
    }


    // Check if the value is greater than 5
    if (value < 5) {
        return false;
    }

    return true;
}

export function validateEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}


export function GetDailyCode(code) {

    function padding(value) {
        if (value < 10)
            return "0" + value.toString();
        else
            return value.toString();
    }

    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    var datestring = year.toString() + padding(month) + padding(day);
    //alert(datestring);
    var value = parseInt(datestring) - 666;
    if (code === null) {
        return value.toString()
    }
    else {
        return code.charAt(0).toLowerCase() + value.toString() + code.charAt(1).toLowerCase();
    }

}
