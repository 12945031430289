import axios from 'axios';



export async function AppPostPayment(data) {
    var config = {
        method: 'post',
        url: '/api/home/getpayment/',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify(data)
    };
    return axios(config)
        .then(res => res.data)
}



