import { IonAvatar, IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonToggle } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import { bookmarkOutline, cardOutline, constructOutline, createOutline,  exitOutline, extensionPuzzleOutline,  eyeOutline,  moonOutline, personOutline, starHalfOutline } from 'ionicons/icons';
import './Menu.css';


import { useQuery } from '@tanstack/react-query';


interface AppPage {
    path: string,
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

interface StateProps {
    darkMode: boolean;
}

interface DispatchProps {
    setDarkMode: any
}

interface MenuProps extends StateProps, DispatchProps { }


const userPages: AppPage[] = [
    {
        title: 'Profile',
        url: '/Profile',
        path: '',
        iosIcon: personOutline,
        mdIcon: personOutline
    }
];
const labels = ['New Construction','Announcement'];


const Menu: React.FC<MenuProps> = ({ darkMode, setDarkMode }) => {
    // USE STATES
    //const auth = useAuth();
    const location = useLocation();



    return (
        <IonMenu contentId="ubmain" type="overlay">
            <IonContent class="ion-no-padding" >
                {/*Utitly Log Place Holder*/} 
                <IonList id="inbox-list0" class="ion-no-padding">
                    <IonItem lines="none" class="ion-no-padding"
                        routerLink="/Landing"
                        onClick={() => window.location.href = "https://orem.org/star/"}
                    >
                        <div style={{ display: "block", marginLeft: "auto", marginRight: "auto", borderRadius: "50%" }}>
                            <IonImg src="/assets/icon/OremLogoX.png" />
                        </div> 
                    </IonItem>
                </IonList>




                <IonList lines="none">
                    <IonListHeader>Settings</IonListHeader>
                    <IonItem>
                        <IonIcon slot="start" icon={moonOutline}></IonIcon>
                        <IonLabel>Dark Mode</IonLabel>
                        <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
                    </IonItem>
                </IonList>

                <IonList id="list0B" >
                    <IonListHeader>Project Overview</IonListHeader>        
                    {/*<IonItem lines="none"*/}
                    {/*    href="javascript:void(0);"*/}
                    {/*    onClick={() => window.open('https://woodywilliams.org/monument-overview.html', '_blank')}>*/}
                    {/*    <IonIcon slot="start" icon={starHalfOutline} md={starHalfOutline} />*/}
                    {/*    <IonLabel>About the Gold Star Families Memorial Monument</IonLabel>*/}
                    {/*</IonItem>*/}
                    {/*<IonItem lines="none"*/}
                    {/*    href="javascript:void(0);"*/}
                    {/*    onClick={() => window.open('https://goldstarfamilyregistry.com/', '_blank')}>*/}
                    {/*    <IonIcon slot="start" icon={starHalfOutline} md={starHalfOutline} />*/}
                    {/*    <IonLabel>Gold Star Family Registry</IonLabel>*/}
                    {/*</IonItem>*/}
                    <IonItem lines="none"
                        href="javascript:void(0);"
                        onClick={() => window.open('https://orem.org/star/', '_blank') }>
                        <IonIcon slot="start" icon={starHalfOutline} md={starHalfOutline} />
                        <IonLabel>Orem Gold Star Memorial Project</IonLabel>
                    </IonItem>
                    

                </IonList>

                {/*<IonList id="ListOTHER">*/}
                {/*    <IonListHeader>NEWS</IonListHeader>*/}
                {/*    {labels.map((label, index) => (*/}
                {/*        <IonItem lines="none" key={index}>*/}
                {/*            <IonIcon slot="start" icon={bookmarkOutline} />*/}
                {/*            <IonLabel>{label}</IonLabel>*/}
                {/*        </IonItem>*/}
                {/*    ))}*/}
                {/*</IonList>*/}
               
            </IonContent>
            </IonMenu>

    );
};

export default Menu;
