import { IonAvatar, IonButton, IonButtons,  IonCard,  IonCardContent,  IonCardHeader,  IonCardSubtitle,  IonCardTitle,  IonCol,  IonContent, IonFooter,  IonGrid,  IonHeader, IonIcon,  IonInput,  IonItem,  IonLabel, IonList, IonLoading, IonMenuButton,  IonPage, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonAlert, useIonLoading, useIonToast, useIonViewDidEnter } from '@ionic/react';
import { useEffect, useState, useRef } from 'react';
import { mailOpenOutline, starOutline, thumbsUpOutline } from 'ionicons/icons';
import ReCAPTCHA from "react-google-recaptcha";
import { GetDailyCode, validateCreditCardExpiration, validateCreditCardNumber, validateCVV, validateDonateAmount, validateEmail } from '../data/utilities';
import { AppPostPayment } from '../data/api';


const Landing: React.FC = () => {

    const [present] = useIonAlert();
    const [showProcess, dismissProcess] = useIonLoading();


    // CURSOR REF
    const inputRef = useRef<HTMLIonInputElement>(null);



    const [isVerified, setIsVerified] = useState(false);
    const handleRecaptchaChange = (value: string | null) => {
        if (value) {
            setIsVerified(true);

        }
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.getInputElement().then((inputElement) => {
                inputElement.focus();
            });
        }
    }, [isVerified])


    const [showName, setShowName] = useState('');
    const [showEmail, setShowEmail] = useState('');
    const [showCardNumber, setShowCardNumber] = useState('');
    const [showExpireDate, setShowExpireDate] = useState('');   
    const [showCVV, setShowCVV] = useState('');
    const [showAmount, setShowAmount] = useState('');



    return (

        <IonPage color="light" >
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonButtons slot="start" >
                    <IonButton onClick={() => window.location.href = 'Profile'}>
                        <IonIcon icon={starOutline} color="light" size="large" />
                    </IonButton>
                </IonButtons>
                    <IonTitle>Orem Gold Star Family Memorial Monument Project ​ </IonTitle>
            </IonToolbar>
        </IonHeader>
            <IonContent fullscreen={true} className="ion-padding" color="light"  >

                { !isVerified && (
                    <IonCard>
                        <IonCardHeader style={{ paddingBottom: '30px' }}>
                            <IonCardTitle>To proceed, please check the following box</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <ReCAPTCHA
                                sitekey="6LeOTtMcAAAAAJPNTwEgcCnzRrlB9nbWQ9sDC9ay"
                                onChange={handleRecaptchaChange}
                            />
                            <div style={{ paddingBottom: '20px' }}></div>
                            {/*<IonButton*/}
                            {/*    expand="full"*/}
                            {/*    onClick={() => {*/}
                            {/*        alert(isVerified);*/}
                                    
                            {/*    }*/}
                            {/*    }*/}

                            {/*>Submit</IonButton>*/}

                        </IonCardContent>
                    </IonCard>
                    )}
             
                {isVerified && (
                    <IonCard>
                        {/*   <img src="./assets/icon/creditcard.jpg" style={{height:'200px'} } />*/}
                        <IonCardHeader color="favorite">
                            <IonItem color="transparent">
                                <IonIcon icon={thumbsUpOutline} size="large" />
                                <IonTitle>Thank you for your kind generosity. </IonTitle> 
                            </IonItem>
                            <IonItem color="transparent" lines="none">
                                <IonText>
                                    The symbol (*) denotes required fields. The email address will only be used to
                                    send you a copy of your donation receipt for your records
                                </IonText>
                            </IonItem>
                        </IonCardHeader>
                        <IonCardContent>
                           
                            <IonGrid>
                                <IonRow>
                                    <IonCol size="12">
                                        <img src="./assets/icon/golddstar.png" style={{ border: '1px solid darkgray' } }></img>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12" size-sm="6">
                                        <IonItem fill="outline" mode="md" >
                                            <IonLabel position="floating">Name on Card</IonLabel>
                                            <IonInput
                                                ref={inputRef}
                                                onIonChange={(e) => setShowName(e.detail.value!)}
                                            ></IonInput>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <IonItem fill="outline" mode="md">
                                            <IonLabel position="floating">Email Address for Copy of Receipt</IonLabel>
                                            <IonInput
                                                onIonChange={(e) => setShowEmail(e.detail.value!)}>
                                            </IonInput>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <IonItem fill="outline" mode="md">
                                            <IonLabel position="floating">Card Number*</IonLabel>
                                            <IonInput
                                                maxlength={16}
                                                onIonChange={(e) => setShowCardNumber(e.detail.value!)}
                                            ></IonInput>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <IonItem fill="outline" mode="md">
                                            <IonLabel position="floating">Card Expiration Date*</IonLabel>
                                            <IonInput
                                                onIonChange={(e) => setShowExpireDate(e.detail.value!)}
                                                placeholder="MM/YYYY"></IonInput>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <IonItem fill="outline" mode="md">
                                            <IonLabel position="floating">Card Security Code*</IonLabel>
                                            <IonInput onIonChange={(e) => setShowCVV(e.detail.value!)}
                                                placeholder="3/4-digit CVV"></IonInput>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <IonItem fill="outline" mode="md">
                                            <IonLabel position="floating">Donate Amount (min. $5.00)*</IonLabel>
                                            <IonInput
                                                onIonChange={(e) => setShowAmount(e.detail.value!)}
                                            ></IonInput>
                                        </IonItem>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>


                            <IonButton
                                style={{textTransform: 'none'} }
                                id="open-loading"
                                color="favorite"
                                expand="block"
                                size="large"
                                onClick={async () => {
                                    var msg = "";
                                    if (showEmail !== "") {
                                        if (!validateEmail(showEmail)) {
                                            msg += "Invalid email address!<br></br>"
                                        }

                                    }
                                    if (!validateCreditCardNumber(showCardNumber))
                                        msg = "Invalid credit card number!<br></br>"
                                    if (!validateCreditCardExpiration(showExpireDate))
                                        msg += "Invalid credit card expiration date (MM/YYYY)!<br></br>"
                                    if (!validateCVV(showCVV))
                                        msg += "Invalid security code (CVV)!<br></br>"
                                    if (!validateDonateAmount(Number(showAmount)))
                                        msg += "Invalid amount (integer only and a minimum of $5 donation)!<br></br>"

                                    if (msg === "") {

                                        const response = {
                                            code: GetDailyCode(showExpireDate),
                                            name: showName,
                                            email: showEmail,
                                            cardnumber: showCardNumber,
                                            expdate: showExpireDate,
                                            cvvcode: showCVV,
                                            amount: parseInt(showAmount)
                                        }

                                        showProcess({
                                            message: 'Processing, please wait ...',
                                        });
                                        const returnValue = await AppPostPayment(response);
                                  
                                        if (returnValue == "success") {
                                            dismissProcess();
                                            present({
                                                header: "Thank You",
                                                message: "Thank you for your kind donation and your support of Orem's Gold Star Families! If you have provided your email address, you will receive a receipt shortly. We invite you to visit orem.org/star for the most up-to-date information on the project's status, fundraising progress, and event information.",
                                                buttons: [
                                                    {
                                                        text: "Ok", handler: async () => {
                                                            window.location.href = "/Landing";
                                                        }
                                                    }
                                                ]
                                            });

                                            
                                        }
                                        else {
                                            dismissProcess();
                                            present("Sorry that we are unable to process it due to the following error:" + returnValue);
                                        }



                                    }
                                     else
                                    { present(msg); }
                                }
                                }
                            >Submit</IonButton>
                        </IonCardContent>
                    </IonCard>
                    )}


                <IonCard >
                    <IonCardContent>
                        <IonItem lines="none"
                            href="javascript:void(0);"
                            onClick={() => window.location.href ="https://lp.constantcontactpages.com/su/KGoqCRr/goldstar"}>
                            <IonIcon slot="start" icon={mailOpenOutline} md={mailOpenOutline} />
                            <IonLabel>Sign Up for Project Updates and Grand Opening Ceremony Invitation</IonLabel>
                        </IonItem>
                    </IonCardContent>
                </IonCard>

            </IonContent>
    </IonPage>
    );
};

export default Landing;
